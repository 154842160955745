import React from 'react'

const SpringPrograms = ({springPrograms}) => {

  return (
    <div id='spring-programs-list'>
        <table>
            <thead></thead>
            <tbody>
                <tr>
                    <td><a href='https://system.gotsport.com/programs/6718I7909?reg_role=player' className='register-program-name' target='_blank' rel='noreferrer'>Intramural</a></td>
                </tr>
            </tbody>
        </table>
    </div>
  )
}

export default SpringPrograms
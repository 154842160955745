import React from 'react'

const WinterPrograms = () => {

  return (
    <div id='winter-programs-list'>
        <table>
            <thead></thead>
            <tbody>
                <tr>
                    <td><a href='https://system.gotsport.com/programs/4P1414699?reg_role=player' className='register-program-name' target='_blank' rel='noreferrer'>Boys Winter Academy</a></td>
                </tr>
                <tr>
                    <td><a href='https://system.gotsport.com/programs/26F899515?reg_role=player' className='register-program-name' target='_blank' rel='noreferrer'>Big Kickers Pre-Academy</a></td>
                </tr>
            </tbody>
        </table>
    </div>
  )
}

export default WinterPrograms